import { Box, styled } from '@mui/material';

export const FormAddContainer = styled(Box)(({ theme }) => ({
  marginBottom: 'auto',
  width: '100%',
  zIndex: 999,
  padding: `0px ${theme.spacing(1.5)}`,
  opacity: 1,
}));

// Styled box for message container, scrollable and fixed height based on viewport

export const MessageContainer = styled(Box)(({ theme }) => ({
  height: 'calc(100vh - 150px)',
  paddingTop: theme.spacing(5),
  width: '100%',
  paddingBottom: theme.spacing(2),
  paddingRight: theme.spacing(1),
  overflowY: 'auto',
  overflowX: 'hidden',
  scrollbarWidth: 'thin',
  '&::-webkit-scrollbar': {
    width: '8px',
    height: 'auto',
  },
  '&:hover': {
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: theme.palette.background.paper,
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.customColor.scrollColor,
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: theme.palette.customColor.scrollColor,
    },
  },
}));
