import React, { useCallback, useMemo, useRef, useState } from 'react';
import { IconButton, useMediaQuery, useTheme } from '@mui/material';
import CustomBox from '../../../../common/CustomBox/CustomBox';
import STChip from '../../../../common/STChip/STChip';
import useGetBotTags from '../../../hooks/useGetBotTags';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Mixpanel } from '../../../../../mixpanel/mixpanel';

interface Props {
  onItemClick(item: any): void;
  tagSlug: string;
  hideRecent?: boolean;
  avatar?: (
    tag: any
  ) =>
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | undefined;
}

const BotTagsList = ({ tagSlug, onItemClick, avatar, hideRecent }: Props) => {
  const { data, loading } = useGetBotTags();
  const theme = useTheme();
  const chipsContainerRef = useRef<HTMLDivElement>(null);
  const [isCanScroll, setIsCanScroll] = useState({
    left: false,
    right: true,
  });
  const isSm = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  const onTagClick = (tag: any) => {
    Mixpanel.track('Bot tag clicked', { tag: tag.title, slug: tag.slug });
    onItemClick(tag);
  };

  const handleScroll = useCallback((direction: 'left' | 'right') => {
    const container = chipsContainerRef.current;
    if (container) {
      const scrollAmount = direction === 'left' ? -200 : 200;
      container.scrollBy({
        left: scrollAmount,
        behavior: 'smooth',
      });

      setTimeout(() => {
        setIsCanScroll({
          left: container.scrollLeft > 0,
          right:
            container.scrollLeft <
            container.scrollWidth - container.clientWidth,
        });
      }, 800);
    }
  }, []);

  const botTags = useMemo(() => {
    return data?.getBotTags?.filter(
      (tag) => !hideRecent || tag.slug !== 'recent'
    );
  }, [data?.getBotTags, hideRecent]);

  if (!botTags || botTags.length === 0) {
    return null;
  }

  const chipsContainerWidth = botTags.reduce((acc, category) => {
    const chipWidth = category.title.length * 12 + (avatar ? 20 : 0);
    return acc + chipWidth;
  }, 0);

  const arrowStyles = {
    position: 'absolute',
    border: '1px solid',
    background: theme.palette.background.paper,
    transition: 'opacity 0.3s',
    top: '30%',
  };
  const minWidthScrollEnable = isSm ? 370 : 600;
  const isRightScrollEnabled =
    isCanScroll.right && chipsContainerWidth > minWidthScrollEnable;

  const rightIconOpacity = isRightScrollEnabled ? 1 : 0;
  return (
    <CustomBox position={'relative'}>
      <div
        ref={chipsContainerRef}
        style={{ position: 'relative', overflow: 'hidden' }}
      >
        <CustomBox
          sx={{
            display: 'flex',
            minWidth: '100%',
            width: chipsContainerWidth,
            gap: theme.spacing(1),
            marginTop: theme.spacing(2),
            overflowY: 'hidden',
            overflowX: 'auto',
          }}
        >
          {botTags.map((category: any) => (
            <STChip
              avatar={avatar && avatar(category)}
              sx={{
                borderRadius: theme.spacing(1),
                whiteSpace: 'nowrap',
                background:
                  category.slug === tagSlug
                    ? theme.palette.customColor.scrollColor
                    : 'transparent',
              }}
              key={category.id}
              onClick={() => onTagClick(category)}
              label={category.title}
              variant={'outlined'}
            />
          ))}
        </CustomBox>
      </div>
      <IconButton
        size="small"
        sx={{
          ...arrowStyles,
          left: 0,
          opacity: isCanScroll.left ? 1 : 0,
        }}
        onClick={() => handleScroll('left')}
      >
        <ChevronLeftIcon />
      </IconButton>
      <IconButton
        size="small"
        sx={{
          ...arrowStyles,
          right: 0,
          opacity: rightIconOpacity,
        }}
        onClick={() => handleScroll('right')}
      >
        <ChevronRightIcon />
      </IconButton>
    </CustomBox>
  );
};

export default BotTagsList;
