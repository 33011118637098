import { FormattedMessage, useIntl } from 'react-intl';
import CustomBox from '../../../../common/CustomBox/CustomBox';
import CustomTypography from '../../../../common/CustomTypography/CustomTypography';
import Bot from '../../../Description/Bots/Bot/Bot';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import BotTagsList from '../BotTagsList/BotTagsList';
import { BoxProps, useTheme } from '@mui/material';
import CustomSearchInput from '../../../../common/CustomSearchInput/CustomSearchInput';
import useGetSearchBots from '../../../../../Hooks/useGetSearchBots';
import { Mixpanel } from '../../../../../mixpanel/mixpanel';
import useChatSession from '../../../hooks/useChatSession';
import useGetChatSessionDefaultBot from '../../../hooks/useGetChatSessionDefaultBot';
import CustomIconButton from '../../../../common/CustomIconButton/CustomIconButton';
import Close from '@mui/icons-material/Close';
import { useContextFormChat } from '../../../hooks/useContextFormChat';

interface props extends BoxProps {
  isDisplayBotsTag?: boolean;
  todoId?: number;
  isDisplaySearch?: boolean;
  onBotClicked?(bot: any): void;
  shouldCreateSession?: boolean;
  handleCloseSearchDialog?(): void;
}
const ListBotByTags = ({
  isDisplayBotsTag,
  onBotClicked,
  todoId,
  handleCloseSearchDialog,
  isDisplaySearch = true,
  shouldCreateSession = false,
  ...rest
}: props) => {
  const theme = useTheme();
  const intl = useIntl();
  const { updateChatSession } = useChatSession();
  const { resetExtraConfig } = useContextFormChat();
  const { data: dataSourceSession } = useGetChatSessionDefaultBot();
  const {
    data,
    loading,
    handleBotClick: createChatSessionAndGo,
    onTagClick,
    onChangeInput,
    inputValue,
    tagSlug,
  } = useGetSearchBots({
    todoId,
    onBotClick: (bot: any) => {
      onBotClicked?.(bot);
    },
  });

  if (loading && !isDisplaySearch) {
    return null;
  }

  const onBotClick = (bot: any) => {
    updateChatSession({
      variables: {
        id: dataSourceSession?.getOneTodoChatSession?.id || 0,
        data: {
          defaultBotId: bot.id,
        },
      },
    });
    onBotClicked?.(bot);
    Mixpanel.track('Select Default LMM Chat', {
      botSlug: bot.slug,
    });
    resetExtraConfig();
  };
  const handleBotClick = (bot: any) => {
    if (!shouldCreateSession) {
      onBotClick(bot);
    } else {
      createChatSessionAndGo(bot);
    }
    resetExtraConfig();
  };

  return (
    <CustomBox>
      {isDisplaySearch && (
        <CustomBox px={1} display={'flex'} gap={1} width={'100%'}>
          <CustomBox sx={{ width: '100%' }}>
            <CustomSearchInput
              loading={loading}
              autoFocus={true}
              searchValue={inputValue || ''}
              searchInputPlaceholder={intl.formatMessage({
                id: 'search.bot.placeholder',
              })}
              onChangeInputSearch={onChangeInput}
            />
          </CustomBox>
          {handleCloseSearchDialog && (
            <CustomIconButton
              onClick={handleCloseSearchDialog}
              size="small"
              sx={{
                padding: 0.2,
                cursor: 'pointer',
                width: '40px',
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: '4px',
              }}
            >
              <Close fontSize="small" />
            </CustomIconButton>
          )}
        </CustomBox>
      )}

      {isDisplayBotsTag && (
        <CustomBox sx={{ mt: theme.spacing(1) }}>
          <BotTagsList
            onItemClick={(id: number) => onTagClick?.(id)}
            tagSlug={tagSlug || ''}
          />
        </CustomBox>
      )}

      {data?.getSearchBots?.length ? (
        <CustomBox {...rest}>
          {data.getSearchBots?.map((bot: any) => (
            <CustomBox
              sx={{
                paddingLeft: theme.spacing(1),
                '&:hover': {
                  backgroundColor: theme.palette.background.paper,
                  cursor: 'pointer',
                  borderRadius: theme.spacing(1),
                },
              }}
            >
              <Bot
                botSlug={bot.slug}
                botname={bot.name}
                botWorkspaceId={bot.workspaceId}
                isForDescription={true}
                avatarSize="large"
                avatar={bot.user?.profilePicture}
                botDescription={bot.description}
                isDisplayDescription={true}
                onClick={() => handleBotClick?.(bot)}
                isBot={false}
                botId={bot.id}
                key={bot.id}
                isOpenDocumentationOnClicked={true}
              />
            </CustomBox>
          ))}
        </CustomBox>
      ) : (
        <CustomBox
          sx={{
            display: 'flex',
            width: '100%',
          }}
        >
          <CustomBox
            sx={{
              mx: 'auto',
              display: 'flex',
              flexDirection: 'column',
              mt: theme.spacing(1),
              justifyContent: 'center',
              height: '60vh',
            }}
          >
            <CustomBox mx={'auto'}>
              <SearchOffIcon
                sx={{
                  width: 50,
                  height: 50,
                  color: theme.palette.grey[200],
                }}
              />
            </CustomBox>
            <CustomTypography>
              <FormattedMessage id="search.no.result" />
            </CustomTypography>
          </CustomBox>
        </CustomBox>
      )}
    </CustomBox>
  );
};
export default ListBotByTags;
