import * as React from 'react';
import { useTheme } from '@mui/material';
import Message from './Message/MessageDataLoader';
import messagesByDate from '../../../utils/messageByDate';
import MessageDivider from './MessageDivider/MessageDivider';
import RealTimeUpdate from './RealtimeUpdate/RealtimeUpdate';
import MessageToReply from './MessageToReply/MessageToReply';
import { Mixpanel } from '../../../mixpanel/mixpanel';
import CustomBox from '../../common/CustomBox/CustomBox';
import MessageRetry from './MessageRetry/MessageRetry';
import { CHAT_MESSAGE_FRAGMENT } from '../../../fragements/message';
import { FragmentType } from '../../../gql';
import { reverseChatMessages } from '../utils/reverseChatMessage';
import FormAddMessage from './FormAddMessage/FormAddMessage';
import HeadContainer from './HeadMessage/HeadContainer';
import ChatOnBoardingContainer from './ChatOnboarding/ChatOnboardingContainer';
import useGenerateText2Speech from '../../../Hooks/useGenerateText2Speech';
import CreditWarningIndication from './CreditWarningIndication/CreditWarningIndication';
import FloatingScroolButton from './FloatingScroolButton/FloatingScroolButton';
import useScroolButton from './FloatingScroolButton/useScroolButton';
import { FormAddContainer, MessageContainer } from './bodyChat.style';
import CustomDropZone from '../../common/CustomDropZone/CustomDropZone';
import { useContextFormChat } from '../hooks/useContextFormChat';

interface props {
  todoId: number;
  messages?: FragmentType<typeof CHAT_MESSAGE_FRAGMENT>[];
  sessionId?: number;
  isBotConfigMode?: boolean;
}

const BodyChat = ({
  todoId,
  messages: chatMessages,
  sessionId,
  isBotConfigMode = false,
}: props) => {
  const { onUploadFile, isInifiniteChat } = useContextFormChat();
  // Reverse messages
  const messages = React.useMemo(() => {
    return reverseChatMessages(chatMessages);
  }, [chatMessages]);
  // Chat context
  // Parent message id for reply
  const theme = useTheme();
  const {
    isDisplayScrollButton,
    onScroll,
    scrollToBottomRef,
    handleScrollBottom,
  } = useScroolButton({ messages });
  const [parentMessageId, setParentMessageId] = React.useState<number | null>(
    null
  );

  //use text to speech
  const {
    isSpeechAudioLoading,
    isPlaying,
    currentMessagePlayingId,
    generateSpeech,
  } = useGenerateText2Speech();

  //Handle text2speech
  const handleTextToSpeech = (messageId: number) => {
    Mixpanel.track('Click Chat Text to speech', {});
    generateSpeech(messageId);
  };
  // Handle reply menu click
  const handleReply = (messageId: number) => {
    setParentMessageId(messageId);
    Mixpanel.track('Reply a message', {});
  };
  const handleCloseReply = () => {
    setParentMessageId(null);
  };
  const onMessageSent = () => {
    setParentMessageId(null);
    handleScrollBottom();
  };
  const isEmptyMessage = !messages || messages.length === 0;

  const messagesGroupByDate = React.useMemo(
    () => messagesByDate(messages),
    [messages]
  );

  const lastMessage: any = messages ? messages[messages.length - 1] : null;
  const lastMessageId = lastMessage ? lastMessage.id : null;
  return (
    <CustomDropZone
      onDropFile={onUploadFile}
      disabled={!isInifiniteChat}
      option={{
        cannotClick: true,
      }}
    >
      <CustomBox
        height={'calc(100vh - 55px)'}
        overflow={'hidden'}
        display={'flex'}
        flexDirection={'column'}
      >
        <MessageContainer
          ref={scrollToBottomRef}
          onScroll={onScroll}
          id="chat-container"
        >
          <HeadContainer />
          {!isBotConfigMode && isEmptyMessage ? (
            <CustomBox
              sx={{
                marginTop: (theme) => theme.spacing(2),
                // Breakpoints xs
                [theme.breakpoints.down('sm')]: {
                  marginTop: (theme) => theme.spacing(2),
                  marginRight: (theme) => theme.spacing(1),
                  marginLeft: (theme) => theme.spacing(1),
                  marginBottom: (theme) => theme.spacing(2),
                },
                [theme.breakpoints.up('xs')]: {
                  marginLeft: (theme) => theme.spacing(2),
                  marginRight: (theme) => theme.spacing(2),
                },
              }}
            >
              <ChatOnBoardingContainer
                todoId={todoId}
                sessionId={sessionId || 0}
              />
            </CustomBox>
          ) : null}
          <CustomBox
            sx={{
              marginTop: (theme) => theme.spacing(1),
            }}
          >
            {Object.keys(messagesGroupByDate).map((date, index) => (
              <React.Fragment key={date}>
                {index !== 0 && <MessageDivider date={date} />}
                {messagesGroupByDate[date].map(
                  (message: any, index: number) => (
                    <>
                      <Message
                        key={index.toString()}
                        todoId={todoId}
                        message={message}
                        isLastMessage={lastMessageId === message.id}
                        onReply={handleReply}
                        sessionId={sessionId as number}
                        isPlaying={
                          isPlaying && currentMessagePlayingId === message.id
                        }
                        isSpeechAudioLoading={isSpeechAudioLoading}
                        handleAudioToggle={() => handleTextToSpeech(message.id)}
                      />
                    </>
                  )
                )}
              </React.Fragment>
            ))}

            {lastMessage && lastMessage.isBotError && (
              <CustomBox
                sx={{
                  marginRight: (theme) => theme.spacing(1),
                }}
              >
                <MessageRetry sessionId={sessionId as number} />
              </CustomBox>
            )}

            <RealTimeUpdate
              todoId={todoId}
              lastMessageId={lastMessageId}
              sessionId={sessionId || 0}
              onNewMessage={() => {}}
              onNewTyping={() => {}}
            />
          </CustomBox>
        </MessageContainer>
        <FormAddContainer id="chat-message-input">
          {parentMessageId && (
            <MessageToReply
              messageId={parentMessageId}
              onClose={handleCloseReply}
            />
          )}

          <CreditWarningIndication />

          <FormAddMessage
            parentMessageId={parentMessageId}
            onMessageSent={onMessageSent}
            sessionId={sessionId}
            isBotConfigMode={isBotConfigMode}
            isEmptyMessage={isEmptyMessage}
          />
        </FormAddContainer>

        {isDisplayScrollButton && (
          <FloatingScroolButton onClick={handleScrollBottom} />
        )}
      </CustomBox>
    </CustomDropZone>
  );
};

export default React.memo(BodyChat);
