import { FC } from 'react';
import CustomBox from '../CustomBox/CustomBox';
import useCreateFile, { FileType } from '../../../Hooks/useCreateFile';
import STButton from '../STButton/STButton';
import CustomTypography from '../CustomTypography/CustomTypography';
import { FormattedMessage } from 'react-intl';
import STProgressCircular from '../STProgressCircular/STProgressCircular';
import Loading from '../Loading/Loading';
import { UploadFileOutlined } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import CustomIconButton from '../CustomIconButton/CustomIconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { getFileNameFromUrl } from '../../../utils/getFileNameFromUrl';
import CustomDropZone from '../CustomDropZone/CustomDropZone';

interface IProps {
  onAddFiles(files: FileType[]): void;
  multiple?: boolean;
  supportedFiletypes?: string;
  loading?: boolean;
  fileFormPlaceholder?: string | null;
  size?: 'large' | 'small';
  isDisplayLoader?: boolean;
  isDirectlyToUpload?: boolean;
  maxFileSize?: number;
  values?: string;
}

const STFileForm: FC<IProps> = ({
  onAddFiles,
  multiple,
  supportedFiletypes,
  isDisplayLoader,
  fileFormPlaceholder,
  size = 'small',
  loading: isLoadingInParent,
  isDirectlyToUpload = true,
  maxFileSize,
  values,
}) => {
  const valueToFileType = () => {
    const arrayValues = values?.split(',').length
      ? values.split(',')
      : [values];

    const newValue: FileType[] = arrayValues
      .filter((value): value is string => {
        if (typeof value !== 'string' || !value) return false;

        try {
          new URL(value);
          return getFileNameFromUrl(value) !== '';
        } catch {
          return false;
        }
      })
      .map((url: string, idx: number) => {
        const name = getFileNameFromUrl(url);
        return {
          id: idx + 1,
          url,
          type: '',
          size: 0,
          name,
          updatedAt: '',
          removed: false,
          createdAt: '',
        };
      });

    return newValue || [];
  };

  const defaultValue = valueToFileType();
  const {
    uploadProgress,
    handleCreateFile,
    isInsupported,
    loading,
    files,
    onDropFile,
    setFiles,
  } = useCreateFile({
    onComplete: (files: FileType[]) => {
      const lastedAndNewValue = [...defaultValue, ...files];
      onAddFiles(lastedAndNewValue);
    },
    supportedFiletypes,
    isDirectlyToUpload,
  });

  const theme = useTheme();

  const onClickUpload = () => {
    handleCreateFile();
  };

  const deleteFileByIndex = (index: number) => () => {
    if (defaultValue.length > 0 && index >= 0 && index < defaultValue.length) {
      const updatedFiles = [...defaultValue];
      updatedFiles.splice(index, 1);
      onAddFiles(updatedFiles);
      setFiles(updatedFiles);
    }
  };

  return (
    <CustomBox>
      <CustomDropZone
        onDropFile={onDropFile}
        multiple={multiple}
        maxFileSize={maxFileSize}
        disabled={loading || isLoadingInParent}
        sx={{
          border: `1px dashed ${
            isInsupported ? theme.palette.error.main : theme.palette.grey[200]
          }`,
          borderRadius: '10px',
          cursor: 'pointer',
          padding: theme.spacing(1),
          textAlign: 'center',
          width: '100%',
          height: size === 'large' ? '200px' : 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <CustomBox>
          {uploadProgress === 0 && !isLoadingInParent && (
            <CustomBox>
              <CustomBox>
                <UploadFileOutlined />
              </CustomBox>
              <CustomTypography variant="caption">
                <FormattedMessage
                  id={fileFormPlaceholder || 'fileForm.dropfileIndication'}
                />
              </CustomTypography>
            </CustomBox>
          )}
          {isInsupported && (
            <CustomTypography
              sx={{ color: theme.palette.error.main, fontSize: '14px' }}
            >
              <FormattedMessage id={'unsupported.file.label'} />
            </CustomTypography>
          )}
          {isDisplayLoader && uploadProgress > 0 && !isLoadingInParent && (
            <CustomBox>
              <CustomBox>
                <STProgressCircular
                  sx={{ color: (theme) => theme.palette.grey[200] }}
                  size={50}
                  value={uploadProgress}
                />
              </CustomBox>
              <CustomBox>
                <CustomTypography variant="caption">
                  <FormattedMessage
                    id="fileForm.uploading"
                    defaultMessage="Uploading"
                  />
                </CustomTypography>
              </CustomBox>
            </CustomBox>
          )}
          {isLoadingInParent || loading ? (
            <CustomBox>
              <CustomBox>
                <Loading
                  sx={{ color: (theme) => theme.palette.grey[200] }}
                  type="circular"
                  size={50}
                />
              </CustomBox>
              <CustomBox>
                <CustomTypography variant="caption">
                  <FormattedMessage
                    id="fileForm.analyzing"
                    defaultMessage="Analyzing"
                  />
                </CustomTypography>
              </CustomBox>
            </CustomBox>
          ) : null}
        </CustomBox>
      </CustomDropZone>
      {defaultValue && defaultValue.length > 0 && (
        <>
          <CustomBox sx={{ marginTop: (theme) => theme.spacing(1) }}>
            <CustomBox>
              <ul>
                {defaultValue.map((file, index) => (
                  <li key={index}>
                    <CustomTypography
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        '& a': {
                          color: (theme) => theme.palette.grey[200],
                        },
                      }}
                    >
                      <a href={file.url} target="_blank" rel="noreferrer">
                        {file.name}
                      </a>
                      <CustomIconButton
                        onClick={deleteFileByIndex(index)}
                        intlId="subtask.delete"
                      >
                        <DeleteIcon />
                      </CustomIconButton>
                    </CustomTypography>
                  </li>
                ))}
              </ul>
            </CustomBox>
          </CustomBox>
          {!isDirectlyToUpload && !isLoadingInParent && (
            <CustomBox sx={{ marginTop: (theme) => theme.spacing(1) }}>
              <STButton
                fullWidth
                sx={{
                  bgcolor:
                    files.length > 0
                      ? theme.palette.primary.main
                      : 'transparent',
                }}
                variant="outlined"
                intlId="fileForm.upload"
                onClick={onClickUpload}
                disabled={loading || isLoadingInParent || !files.length}
              />
            </CustomBox>
          )}
        </>
      )}
    </CustomBox>
  );
};

export default STFileForm;
