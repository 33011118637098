import { ChangeEvent, FC, FormEvent, useState } from 'react';
import {
  FormField,
  FormFieldType as FFType,
  Maybe,
} from '../../../gql/graphql';
import CustomBox from '../CustomBox/CustomBox';
import { StyledTextField } from '../../Auth/AccountForm/EmailInput/EmailInput';
import TextInput from '../TextInput/TextInput';
import STSelect from '../STSelect/STSelect';
import orderBy from 'lodash/orderBy';
import CustomTypography from '../CustomTypography/CustomTypography';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import Slider from '@mui/material/Slider';
import CustomDialogColorPicker from '../CustomDialogColorPicker/CustomDialogColorPicker';
import CustomButton from '../CustomButton/CustomButton';
import ColorizeIcon from '@mui/icons-material/Colorize';
import STSwitch from '../STSwitch/STSwitch';
import STFileForm from '../STFileForm/STFileForm';
import STImageForm from '../STImageForm/STImageForm';
import STFileModern from '../STFileModern/STFileModern';
import { FileType } from '../../../Hooks/useCreateFile';
import STJsonEditor from '../STJsonEditor/STJsonEditor';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Autocomplete from '@mui/material/Autocomplete';
import { Radio, TextField } from '@mui/material';
import STMagicPrompt from '../STMagicPrompt/STMagicPrompt';

export interface FormFieldType extends FormField {
  rangeStep?: number;
  rangeMin?: number;
  rangeMax?: number;
  jsxField?: React.ReactNode;
  defaultColors?: string[];
  selectedColor?: string;
  isNotdisablebeforDate?: boolean;
  indicationText?: string;
}
interface IProps {
  fields: FormFieldType[];
  currentValues: any;
  onChange: (name: string, value: any) => void;
  onChangeWithEvent?: (e: React.FormEvent<HTMLInputElement>) => void;
  formik?: any;
}

export const FieldHelp = ({
  description,
  label,
}: {
  description?: Maybe<string>;
  label: string;
}) => {
  return (
    <>
      <CustomTypography
        sx={{
          pt: (theme) => theme.spacing(1),
          fontWeight: 'bold',
        }}
      >
        {label}
      </CustomTypography>
      {description && (
        <CustomTypography
          sx={{
            fontSize: '12px',
            //color: (theme) => theme.palette.grey[200],
          }}
        >
          {description}
        </CustomTypography>
      )}
    </>
  );
};

export const IndicationText = ({ label }: { label?: string }) => {
  return (
    <>
      {label && (
        <CustomTypography
          sx={{
            fontSize: '13px',
            mt: (theme) => theme.spacing(1),
          }}
        >
          {label}
        </CustomTypography>
      )}
    </>
  );
};

const STDynamicForms: FC<IProps> = ({
  fields,
  currentValues,
  onChange,
  onChangeWithEvent,
  formik,
}) => {
  const [openColor, setOpenColor] = useState(false);
  const [selectedColor, setSelectedColor] = useState('#fff');
  const [activeColorName, setActiveColorName] = useState('');
  // Sort fields by order
  fields = orderBy(fields, ['order'], ['asc']);
  const handleChange = (
    e: FormEvent<HTMLInputElement> | any,
    field: FormField
  ) => {
    const value =
      field.type === 'NUMBER'
        ? Number(e.currentTarget.value)
        : field.type === 'RANGE'
        ? e.target.value
        : field.type === 'SWITCH'
        ? e.target.checked
        : e.currentTarget.value;
    onChange(field.formName || field.label, value);
    if (onChangeWithEvent) {
      onChangeWithEvent(e);
    }
  };

  const handleDateChange = (field: FormField, value: any) => {
    const formattedValue = value.toISOString();
    onChange(field.formName || field.label, formattedValue);
  };

  const handleColorChange = ({
    name,
    value,
  }: {
    name?: string;
    value: string;
  }) => {
    setSelectedColor(value);
    onChange(name || activeColorName, value);
  };

  const handleCloseColor = () => {
    setOpenColor(false);
    setActiveColorName('');
  };
  const onAddFiles = (formName: string, files: FileType[]) => {
    if (files.length === 0) {
      onChange(formName, '');
      return;
    }

    if (files.length > 1) {
      onChange(formName, files.map((file) => file.url).join(','));
      return;
    }

    onChange(formName, files[0].url);
  };
  const displayErrorField = (field: FormField) => {
    if (formik?.errors && formik?.errors[field.formName || field.label]) {
      return (
        <CustomBox>
          <CustomTypography
            sx={{
              color: 'error.main',
            }}
            color="error"
            variant="caption"
          >
            {formik?.errors[field.formName || field.label]}
          </CustomTypography>
        </CustomBox>
      );
    }
    return null;
  };

  return (
    <CustomBox>
      {fields.map((field) => {
        // If text field
        if (
          field.type === 'TEXT' ||
          field.type === 'URL' ||
          field.type === FFType.DatabaseUri
        ) {
          return (
            <CustomBox mb={1}>
              <FieldHelp label={field.label} description={field.description} />
              <TextInput
                name={field.formName || field.label}
                value={currentValues[field.formName || field.label]}
                onChange={(e) => handleChange(e, field)}
                placeholder={field.placeholder || field.label}
                //onBlur={formik?.handleBlur}
              />
              {displayErrorField(field)}
              <IndicationText label={field.indicationText} />
            </CustomBox>
          );
        }
        // If file field
        if (field.type === 'FILE') {
          return (
            <CustomBox mb={1}>
              <CustomBox mb={1}>
                <FieldHelp
                  label={field.label}
                  description={field.description}
                />
              </CustomBox>
              <CustomBox>
                <STFileForm
                  onAddFiles={(files) => onAddFiles(field.formName, files)}
                  isDirectlyToUpload={true}
                  multiple={false}
                  supportedFiletypes={field.supportedFiletypes ?? undefined}
                  values={currentValues[field.formName || field.label]}
                />
              </CustomBox>
              {displayErrorField(field)}
              <IndicationText label={field.indicationText} />
            </CustomBox>
          );
        }
        //@ts-ignore
        if (field.type === 'FILE_MULTIPLE') {
          return (
            <CustomBox mb={1}>
              <CustomBox mb={1}>
                <FieldHelp
                  label={field.label}
                  description={field.description}
                />
              </CustomBox>
              <CustomBox>
                <STFileForm
                  onAddFiles={(files) => onAddFiles(field.formName, files)}
                  isDirectlyToUpload={true}
                  multiple={true}
                  supportedFiletypes={field.supportedFiletypes ?? undefined}
                  values={currentValues[field.formName || field.label]}
                />
              </CustomBox>
              {displayErrorField(field)}
              <IndicationText label={field.indicationText} />
            </CustomBox>
          );
        }

        // If file modern field
        if (field.type === 'FILE_MODERN') {
          console.log('field.supportedFiletypes', field);
          return (
            <CustomBox mb={1}>
              <CustomBox mb={1}>
                <FieldHelp
                  label={field.label}
                  description={field.description}
                />
              </CustomBox>
              <CustomBox>
                <STFileModern
                  onAddFiles={(files) => onAddFiles(field.formName, files)}
                  supportedFiletypes={field.supportedFiletypes ?? undefined}
                  placeholder={field.placeholder || field.label}
                  value={currentValues[field.formName || field.label]}
                />
              </CustomBox>
              {displayErrorField(field)}
              <IndicationText label={field.indicationText} />
            </CustomBox>
          );
        }

        // If file Image field
        if (field.type === 'IMAGE') {
          return (
            <CustomBox mb={1}>
              <FieldHelp label={field.label} description={field.description} />
              <CustomBox>
                <STImageForm
                  onAddFiles={(files) => onAddFiles(field.formName, files)}
                  supportedFiletypes={
                    field.supportedFiletypes ??
                    'image/jpeg,image/png,image/gif,image/jpg,image/webp'
                  }
                  value={currentValues[field.formName || field.label]}
                  placeholder={field.placeholder || field.label}
                />
              </CustomBox>
              {displayErrorField(field)}
              <IndicationText label={field.indicationText} />
            </CustomBox>
          );
        }

        // If text field textarea
        if (field.type === 'TEXTAREA' || field.type === FFType.MultipeUrl) {
          return (
            <CustomBox
              sx={{
                '& .css-5l2pcp-MuiInputBase-root-MuiOutlinedInput-root': {
                  background: (theme) => theme.palette.primary.main,
                  padding: '8px',
                },
                position: 'relative',
              }}
              mb={1}
            >
              <FieldHelp label={field.label} description={field.description} />
              <TextInput
                name={field.formName || field.label}
                value={currentValues?.[field.formName || field.label]}
                onChange={(e) => handleChange(e, field)}
                placeholder={field.placeholder || field.label}
                multiline
                minRows={6}
                maxRows={40}
              />
              {displayErrorField(field)}
              {field.promptText &&
              currentValues?.[field.formName || field.label] ? (
                <CustomBox
                  sx={{
                    position: 'absolute',
                    bottom: '8px',
                    right: '8px',
                  }}
                  mb={1}
                >
                  <STMagicPrompt
                    onRegenerate={(value) => onChange(field.formName, value)}
                    prompt={field.promptText || ''}
                    textPrompt={currentValues?.[field.formName || field.label]}
                  />
                </CustomBox>
              ) : null}
              <IndicationText label={field.indicationText} />
            </CustomBox>
          );
        }

        // If json field
        if (field.type === 'JSON') {
          return (
            <CustomBox mb={1}>
              <FieldHelp label={field.label} description={field.description} />
              <STJsonEditor
                label={field.label}
                placeholder={field.placeholder || field.label}
                value={currentValues[field.formName || field.label]}
                onChange={(value, isValid) => {
                  onChange(field.formName || field.label, value);
                }}
              />
              {displayErrorField(field)}
              <IndicationText label={field.indicationText} />
            </CustomBox>
          );
        }
        // If number field
        if (field.type === 'NUMBER') {
          return (
            <CustomBox mb={1}>
              <FieldHelp label={field.label} description={field.description} />
              <TextInput
                name={field.formName || field.label}
                value={currentValues[field.formName || field.label]}
                onChange={(e) => handleChange(e, field)}
                placeholder={field.placeholder || field.label}
                type="number"
              />
              {displayErrorField(field)}
              <IndicationText label={field.indicationText} />
            </CustomBox>
          );
        }

        // If select field
        if (field.type === 'SELECT') {
          return (
            <CustomBox mb={1}>
              <FieldHelp label={field.label} description={field.description} />
              <STSelect
                name={field.formName || field.label}
                value={currentValues[field.formName || field.label]}
                onChange={(e) => handleChange(e, field)}
                options={field.options?.map((option) => {
                  return {
                    label: option.label,
                    value: option.value,
                  };
                })}
              />
              {displayErrorField(field)}
              <IndicationText label={field.indicationText} />
            </CustomBox>
          );
        }
        if (field.type === 'AUTOCOMPLETE') {
          return (
            <CustomBox mb={1}>
              <FieldHelp label={field.label} description={field.description} />
              <Autocomplete
                sx={{ mt: 1 }}
                fullWidth
                defaultValue={currentValues[field.formName || field.label]}
                value={currentValues[field.formName || field.label]}
                onSelect={(e) => handleChange(e, field)}
                renderInput={(params) => (
                  <TextField
                    name={field.formName || field.label}
                    value={currentValues[field.formName || field.label]}
                    onChange={(e) => handleChange(e, field)}
                    placeholder={field.placeholder || field.label}
                    {...params}
                  />
                )}
                options={field.options || []}
              />
              {displayErrorField(field)}
              <IndicationText label={field.indicationText} />
            </CustomBox>
          );
        }

        if (field.type === 'RADIO') {
          return (
            <CustomBox mb={1}>
              <FormControl>
                <FieldHelp
                  label={field.label}
                  description={field.description}
                />
                <RadioGroup
                  value={currentValues[field.formName || field.label]}
                >
                  {field.options?.map((option) => (
                    <FormControlLabel
                      value={option.value}
                      control={
                        <Radio
                          checked={
                            currentValues[field.formName || field.label] ===
                            option.value
                          }
                          onChange={(e) => handleChange(e, field)}
                        />
                      }
                      sx={{
                        // Change label text color depending of theme dark or light
                        color: (theme) =>
                          theme.palette.mode === 'dark'
                            ? theme.palette.text.primary
                            : 'initial',
                      }}
                      label={option.label}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </CustomBox>
          );
        }

        // If text field textarea
        if (field.type === 'DATE') {
          return (
            <CustomBox mb={1}>
              <FieldHelp label={field.label} description={field.description} />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}>
                  <DatePicker
                    sx={{ width: '100%' }}
                    componentsProps={{
                      popper: {
                        sx: {
                          zIndex: 99999,
                        },
                      },
                    }}
                    shouldDisableDate={(date) => {
                      if (field.isNotdisablebeforDate) {
                        return false;
                      }
                      return date.isBefore(dayjs(), 'day');
                    }}
                    value={dayjs(currentValues[field.formName || field.label])}
                    onChange={(newValue) => handleDateChange(field, newValue)}
                  />
                </DemoContainer>
              </LocalizationProvider>
              {displayErrorField(field)}
              <IndicationText label={field.indicationText} />
            </CustomBox>
          );
        }
        // If text field range
        if (field.type === 'RANGE') {
          const value = currentValues[field.formName || field.label] || 0;
          const handleNumberValue = (e: ChangeEvent<HTMLInputElement>) => {
            if (field?.rangeMax && +e.target.value > +field?.rangeMax) {
              return;
            }
            handleChange(e, field);
          };
          return (
            <CustomBox mb={1}>
              <FieldHelp label={field.label} description={field.description} />
              <CustomBox
                mt={2}
                sx={{
                  boxSizing: 'border-box',
                  display: 'grid',
                  gridTemplateColumns: '15% 82.5%',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  paddingRight: (theme) => theme.spacing(1),
                }}
              >
                <StyledTextField
                  type="number"
                  name={field.formName || field.label}
                  value={currentValues[field.formName || field.label]}
                  onChange={handleNumberValue}
                  placeholder={field.placeholder || field.label}
                  sx={{
                    '& .css-190kuoi-MuiInputBase-input-MuiOutlinedInput-input':
                      {
                        padding: (theme) => theme.spacing(1.5),
                      },
                  }}
                  //onBlur={formik?.handleBlur}
                />

                <Slider
                  value={value}
                  valueLabelDisplay="auto"
                  marks={true}
                  min={field.rangeMin}
                  step={field.rangeStep}
                  max={field.rangeMax}
                  onChange={(e: any) => handleChange(e, field)}
                />
              </CustomBox>
            </CustomBox>
          );
        }
        if (field.type === 'CUSTOM') {
          return (
            <>
              <CustomBox mb={1}>{field.jsxField}</CustomBox>
            </>
          );
        }
        if (field.type === 'SWITCH') {
          const value = currentValues[field.formName || field.label];
          return (
            <CustomBox
              mb={1}
              display={'flex'}
              alignItems={'center'}
              width={'center'}
            >
              <CustomBox>
                <FieldHelp
                  label={field.label}
                  description={field.description}
                />
              </CustomBox>
              <CustomBox ml={'auto'}>
                <STSwitch
                  checked={value}
                  onClick={(e: any) => handleChange(e, field)}
                  disabled={false}
                />
              </CustomBox>
            </CustomBox>
          );
        }
        if (field.type === 'COLOR') {
          const handleOpenColor = () => {
            setOpenColor(true);
            setSelectedColor(field?.selectedColor || '');
            setActiveColorName(field.formName);
          };

          return (
            <CustomBox mb={1}>
              <FieldHelp label={field.label} description={field.description} />
              <CustomBox
                sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1 }}
              >
                {field?.defaultColors?.map((color, i) => (
                  <CustomButton
                    variant="contained"
                    sx={{
                      backgroundColor: color,
                      width: 32,
                      height: 32,
                      minWidth: 0,
                      ...(i === 0 ? { border: '2px solid' } : {}),
                    }}
                    onClick={() =>
                      handleColorChange({ name: field.formName, value: color })
                    }
                    label={color === 'transparent' ? 'No' : ''}
                  />
                ))}
                <CustomButton
                  variant="contained"
                  sx={{
                    width: 32,
                    height: 32,
                    minWidth: 0,
                  }}
                  onClick={() => handleOpenColor()}
                >
                  <ColorizeIcon fontSize="small" />
                </CustomButton>
              </CustomBox>
            </CustomBox>
          );
        }
      })}
      <CustomDialogColorPicker
        open={openColor}
        value={selectedColor}
        handleClose={handleCloseColor}
        onColorPickerInfoChange={(value) =>
          handleColorChange({ value: value.hex })
        }
      />
    </CustomBox>
  );
};

export default STDynamicForms;
