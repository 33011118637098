import { FC } from 'react';
import CustomBox from '../../../common/CustomBox/CustomBox';
import CustomButton from '../../../common/CustomButton/CustomButton';
import { FormattedMessage } from 'react-intl';
import useStopAIResponse from '../../hooks/useStopAIResponse';
import Stop from '@mui/icons-material/Stop';
import useGetChatContext from '../ChatBodyContainer/useGetChatContext';
import React from 'react';

interface IProps {
  botIds: number[];
  onStop: () => void;
}

const StopChat: FC<IProps> = ({ botIds, onStop }) => {
  const chatContext = useGetChatContext();
  const { stop, loading: loadingStopAIResponse } = useStopAIResponse();

  const sessionId = chatContext.sessionId || 0;
  const handleStop = () => {
    Promise.all(botIds.map((botId) => stop(sessionId, botId))).then(onStop);
  };

  return (
    <CustomBox
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        opacity: 1,
      }}
    >
      <CustomButton
        variant="outlined"
        color="secondary"
        size="small"
        sx={{
          textTransform: 'none',
          height: '32px',
          backgroundColor: (theme) => theme.palette.background.paper,
        }}
        onClick={handleStop}
        startIcon={<Stop sx={{ color: 'secondary' }} />}
        isLoading={loadingStopAIResponse}
      >
        <FormattedMessage
          id="chat.stop.respond"
          defaultMessage="Stop responding"
        />
      </CustomButton>
    </CustomBox>
  );
};

export default React.memo(StopChat);
