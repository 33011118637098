import * as React from 'react';
import Paper from '@mui/material/Paper';
import { MentionsInput, Mention } from 'react-mentions';
import { styled, useTheme } from '@mui/material';
import Bot from '../../../Description/Bots/Bot/Bot';
import scrollStyle from '../../../scrollStyle';
import PropsActions from './PropsActions/PropsActions';
import SpeechRecognition from 'react-speech-recognition';
import BotUI from './BotUI/BotUI';

const PaperStyled = styled(Paper)(({ theme }) => ({
  p: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  maxHeight: 250,
  border: `1px solid ${theme.palette.divider}`,
  transition: 'all 0.1s',
}));

interface IProps {
  onChangeForm(e: any): void;
  onSendForm(e: any): void;
  handelFileRecordingChange(file: any): void;
  files: any;
  messageValue: string;
  isSendMessageLoading: boolean;
  onKeyDown(
    e:
      | React.KeyboardEvent<HTMLInputElement>
      | React.KeyboardEvent<HTMLTextAreaElement>
  ): void;
  bots: Array<{
    __typename?: 'Bot';
    id: number;
    name: string;
    slug: string;
    user?: {
      __typename?: 'User';
      id: number;
      firstName?: string | null;
      lastName?: string | null;
      profilePicture?: string | null;
    } | null;
  }>;
  involvedUsers: Array<{
    __typename?: 'User';
    id: number;
    firstName?: string | null;
    lastName?: string | null;
    profilePicture?: string | null;
    bot?: {
      __typename?: 'Bot';
      id: number;
      name: string;
      slug: string;
    } | null;
  }>;
  onAddMentionUser(id: number | string, display: string): void;
  onPaste: any;
  onMouseEnterDropzone(): void;
  onMouseLeaveDropzone(): void;
  placeholder?: string;
  acceptFileInput?: boolean;
  handleValidePrompts(prompts: string): void;
  isBotConfigMode?: boolean;
}
const MessageInput: React.FC<IProps> = ({
  messageValue,
  handelFileRecordingChange,
  isSendMessageLoading,
  onMouseEnterDropzone,
  onMouseLeaveDropzone,
  onChangeForm,
  onSendForm,
  onPaste,
  files,
  acceptFileInput,
  onKeyDown,
  placeholder,
  involvedUsers,
  onAddMentionUser,
  handleValidePrompts,
  isBotConfigMode = false,
}) => {
  const [isRecording, setRecording] = React.useState(false);

  const [mediaRecorder, setMediaRecorder] =
    React.useState<MediaRecorder | null>(null);
  const theme = useTheme();

  const botsToMention = involvedUsers.map((user: any) => ({
    id: user.id,
    display: `${user.firstName}`,
    profil: user.profilePicture,
    isBot: user.bot,
    botId: user.bot?.id,
  }));
  //starting video screencast

  const startRecording = async () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getDisplayMedia) {
      return;
    }
    try {
      const stream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
        audio: true,
      });
      if (stream === null) {
        return;
      } else if ('getVideoTracks' in stream) {
        const chunks: Blob[] = [];
        const recorder = new MediaRecorder(stream);

        recorder.ondataavailable = (e) => {
          if (e.data.size > 0) {
            chunks.push(e.data);
          }
        };

        recorder.onstop = () => {
          const blob = new Blob(chunks, { type: 'video/mp4' });
          const file = new File([blob], 'recorded-video.mp4', {
            type: 'video/mp4',
          });
          handelFileRecordingChange(file);
        };

        recorder.start();
        setMediaRecorder(recorder);
        setRecording(true);
      }
    } catch {
      setRecording(false);
    }
  };
  //stoping video screencast
  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      const tracks = mediaRecorder.stream.getTracks();
      tracks.forEach((track) => track.stop());
    }
    setRecording(false);
  };

  const [elevation, setElevation] = React.useState(1);

  const botsSuggestion: any = (bot: any) => {
    return (
      <Bot
        avatar={bot.profil}
        botname={`@${bot.display}`}
        isBot={bot.isBot}
        botId={0}
      />
    );
  };
  React.useEffect(() => {
    if (files.length) {
      setRecording(false);
    }
  }, [files]);

  // const handleTransciptAudio = (data: any) => {
  //   if (data) {
  //     Mixpanel.track('Chat Transcript Audio', {});

  //     if (data.file) {
  //       handelFileRecordingChange(data.file);
  //     }
  //     if (data.text) {
  //       const e = {
  //         target: {
  //           value: messageValue + ' ' + data.text,
  //         },
  //       };
  //       onChangeForm(e);
  //     }
  //   }
  // };
  const handleTransciptAudio = (word: string) => {
    if (word) {
      if (word) {
        const e = {
          target: {
            value: messageValue + ' ' + word,
          },
        };
        onChangeForm(e);
      }
    }
  };
  return (
    <>
      <BotUI />
      <PaperStyled elevation={elevation}>
        <MentionsInput
          onKeyDown={onKeyDown}
          onPaste={onPaste}
          contentEditable={true}
          value={messageValue}
          onFocus={() => {
            setElevation(4);
            SpeechRecognition.stopListening();
          }}
          onBlur={() => setElevation(1)}
          onChange={onChangeForm}
          placeholder={placeholder}
          style={{
            maxHeight: 200,
            width: '100%',

            fontSize: theme.typography.body1.fontSize,
            '&multiLine': {
              control: {
                maxHeight: 240,
                minHeight: 40,
              },
              highlighter: {
                width: '100%',
                marginLeft: '7px',
                margintTop: '1px',
                maxHeight: 240,
              },
              input: {
                outline: 'none',
                border: 'none',
                color: theme.palette.text.primary,
                padding: '8px 0 8px 8px',
                overflowY: 'auto',
                // Scroll x
                overflowX: 'auto',
              },
            },
            suggestions: {
              list: {
                backgroundColor: theme.palette.background.paper,
                borderRadius: 8,
                maxHeight: '230px',
                overflowY: 'auto',
                fontSize: theme.typography.body1.fontSize,
                boxShadow: '0 2px 2px 3px rgba(0, 0, 0, 0.2)',
                marginTop: 'auto',
                ...scrollStyle,
              },
              item: {
                padding: '0.5px 10px',
                textTransform: 'capitalize',
                '&focused': {
                  backgroundColor: theme.palette.secondary.main,
                },
              },
            },
            '&singleLine': {
              display: 'inline-block',
              width: 180,
              highlighter: {
                padding: 1,
                border: '2px inset transparent',
              },

              input: {
                padding: 1,
                outline: 'none',
                width: '100%',
                border: 'none',
              },
            },
          }}
          allowSuggestionsAboveCursor={true}
        >
          <Mention
            trigger="@"
            data={botsToMention}
            markup={'@[__display__](__slug__)'}
            onAdd={onAddMentionUser}
            renderSuggestion={botsSuggestion}
          />
        </MentionsInput>

        <PropsActions
          handleValidePrompts={handleValidePrompts}
          isRecording={isRecording}
          stopRecording={stopRecording}
          startRecording={startRecording}
          acceptFileInput={acceptFileInput}
          onMouseEnterDropzone={onMouseEnterDropzone}
          onMouseLeaveDropzone={onMouseLeaveDropzone}
          isSendMessageLoading={isSendMessageLoading}
          onSendForm={onSendForm}
          files={files}
          messageValue={messageValue}
          handleTransciptAudio={handleTransciptAudio}
          isBotConfigMode={isBotConfigMode}
        />

        {/* <SnackBar
          label={
            <CustomTypography>
              <FormattedMessage id="recording.error" />
            </CustomTypography>
          }
          open={isShow}
          variant="error"
          callback={() => setIsShow(false)}
        />
        <SnackBar
          label={
            <CustomTypography>
              <FormattedMessage id="recordingNotSupported.error" />
            </CustomTypography>
          }
          open={recordingNotSupported}
          variant="error"
          callback={() => setRecordingNotSupported(false)}
        /> */}
      </PaperStyled>
    </>
  );
};

export default MessageInput;
