import { useSubscription } from '@apollo/client';
import React from 'react';
import { graphql } from '../../../../gql';
import CustomBox from '../../../common/CustomBox/CustomBox';
import useGetChatContext from '../ChatBodyContainer/useGetChatContext';
import StopChat from '../StopChat/StopChat';
import useRealtimeUpdate from './useRealtimeUpdate';
import { onAfterSendMessage } from '../../utils/onAfterSendMessage';
import SingleBotRealtimeUpdate from './SingleBotRealtimeUpdate';
import FollowUpQuestions from '../FollowUpQuestions/FollowUpQuestions';
import BotSuggestions from '../Message/BotSuggestions/BotSuggestions';

const NEW_MESSAGE = graphql(`
  subscription newMessage($sessionId: Float!) {
    newMessage(sessionId: $sessionId) {
      id
      createdAt
      content
      todoChatId
      isBotError
      isSubscriptionUsageLimitReachedMessage
      htmlPreviewUrl
      htmlContent
      files {
        id
        name
        url
        type
        size
      }
      sentBy {
        id
        firstName
        lastName
        profilePicture
        bot {
          id
        }
      }
    }
  }
`);

const NEW_INTERMEDIATE_MESSAGE = graphql(`
  subscription newIntermediateMessage($sessionId: Float!) {
    newIntermediateMessage(sessionId: $sessionId) {
      id
      createdAt
      content
      todoChatId
      isBotError
      files {
        id
        name
        url
        type
        size
      }
      sentBy {
        id
        firstName
        lastName
        profilePicture
      }
    }
  }
`);

const STREAM_MESSAGE_SUBSCRIPTION = graphql(`
  subscription StreamMessageSubscription($sessionId: Float!) {
    onMessageStream(sessionId: $sessionId) {
      id
      messageChunk
      botId
      botResponseMessageId
      isStoppable
    }
  }
`);

// Subscription user is typing

const USER_TYPING = graphql(`
  subscription userTyping($sessionId: Float!) {
    userTyping(sessionId: $sessionId) {
      id
      firstName
      lastName
      profilePicture
      bot {
        id
        inProgressText
        inProgressTextFR
        baseBot {
          id
          inProgressText
          inProgressTextFR
        }
      }
      __typename
    }
  }
`);
interface IUserTyping {
  id: number;
  firstName: string;
  lastName: string;
  profilePicture?: string;
}

interface IProps {
  sessionId: number;
  onNewMessage: () => void;
  onNewTyping: () => void;
  todoId: number;
  lastMessageId?: number;
}
const botIdToString = (botId: number | null | undefined) => {
  return botId ? botId.toString() : '';
};

const isContainBotStoppable = (botThatStream: any) => {
  return Object.values(botThatStream).some((bot: any) => bot.isStoppable);
};

const getAllStoppableBots = (botThatStream: any) => {
  return Object.values(botThatStream).filter((bot: any) => bot.isStoppable);
};

const RealTimeUpdate: React.FC<IProps> = ({
  onNewMessage,
  sessionId,
  onNewTyping,
  todoId,
  lastMessageId,
}) => {
  const chatContext = useGetChatContext();
  const enableFollowupQuestion = chatContext.enableFollowupQuestion;

  const { addMessageToTodoChat } = useRealtimeUpdate();
  const [botUsersThatTyping, setBotUsersThatTyping] = React.useState<any>({});
  const [botsThatActuallyStream, setBotsThatActuallyStream] =
    React.useState<any>({});

  const { data: streamMessageData } = useSubscription(
    STREAM_MESSAGE_SUBSCRIPTION,
    {
      variables: {
        sessionId: sessionId,
      },
      shouldResubscribe: true,
      onSubscriptionData: ({ client, subscriptionData }) => {
        const botId = botIdToString(
          subscriptionData?.data?.onMessageStream?.botId
        );
        if (botId && !botsThatActuallyStream[botId]) {
          setBotsThatActuallyStream((prev: any) => {
            return {
              ...prev,
              [botId]: {
                botId: subscriptionData?.data?.onMessageStream?.botId,
                isStoppable:
                  subscriptionData?.data?.onMessageStream?.isStoppable || false,
              },
            };
          });
        }

        addMessageToTodoChat(client, subscriptionData);
        // If botThatStreamConfig has one key
        if (!chatContext.defaultBot?.isMultiBot) {
          onNewMessage();
        }
      },
      onError: (err) => {
        console.log('err', err);
      },
    }
  );

  const { loading } = useSubscription(NEW_MESSAGE, {
    variables: {
      sessionId: sessionId,
    },
    shouldResubscribe: true,
    onSubscriptionData: ({ client, subscriptionData }) => {
      if (subscriptionData?.data?.newMessage) {
        const botId = botIdToString(
          subscriptionData?.data?.newMessage?.sentBy?.bot?.id
        );
        // Si le bot n'est pas en train de streamer
        if (!botsThatActuallyStream[botId]) {
          onAfterSendMessage({
            cache: client.cache,
            newMessage: subscriptionData?.data?.newMessage,
            sessionId: sessionId,
            todoId: todoId,
          });
        }
        if (!chatContext.defaultBot?.isMultiBot) {
          onNewMessage();
        }

        // Remove the bot from the bothThatActuallyStream
        if (botId && botsThatActuallyStream[botId]) {
          setBotsThatActuallyStream((prev: any) => {
            const newBotsThatActuallyStream = { ...prev };
            delete newBotsThatActuallyStream[botId];
            return newBotsThatActuallyStream;
          });
        }
        // Get sentBy botId and set null it if in the botUsersThatTyping

        if (botId && botUsersThatTyping[botId]) {
          setBotUsersThatTyping((prev: any) => {
            const newBotUsersThatTyping = { ...prev };
            delete newBotUsersThatTyping[botId];
            return newBotUsersThatTyping;
          });
        }
      }
    },
    onError: (err) => {
      console.log('err', err);
    },
  });

  const { loading: loadingIntermediate } = useSubscription(
    NEW_INTERMEDIATE_MESSAGE,
    {
      variables: {
        sessionId: sessionId,
      },
      onSubscriptionData: ({ client, subscriptionData }) => {
        if (subscriptionData?.data?.newIntermediateMessage) {
          onAfterSendMessage({
            cache: client.cache,
            newMessage: subscriptionData?.data?.newIntermediateMessage,
            sessionId: sessionId,
            todoId: todoId,
          });

          onNewMessage();
        }
      },
    }
  );

  // Subscription user is typing
  const { data: dataUserTyping } = useSubscription(USER_TYPING, {
    variables: {
      sessionId: sessionId,
    },
    onSubscriptionData: ({ client, subscriptionData }) => {
      // Add the user to botUsersThatTyping object
      if (subscriptionData?.data?.userTyping) {
        setBotUsersThatTyping((prev: any) => {
          return {
            ...prev,
            [botIdToString(subscriptionData?.data?.userTyping?.bot?.id)]: {
              ...subscriptionData?.data?.userTyping,
            },
          };
        });
        if (!chatContext.defaultBot?.isMultiBot) {
          onNewTyping();
        }
      }
      if (!chatContext.defaultBot?.isMultiBot) {
        onNewTyping();
      }
    },
  });
  const handleStop = () => {
    setBotUsersThatTyping({});
  };

  let isDefaultBotStopable = Boolean(
    chatContext.defaultBot?.isStoppable &&
      botsThatActuallyStream[botIdToString(chatContext.defaultBot?.id)]
  );
  // Ou si le bot par defaut n'est streamable mais stoppable
  isDefaultBotStopable =
    isDefaultBotStopable ||
    (chatContext.defaultBot?.isStoppable &&
      botUsersThatTyping[botIdToString(chatContext.defaultBot?.id)]);

  const displayStop =
    isDefaultBotStopable || isContainBotStoppable(botsThatActuallyStream);

  const allStoppableBots = isDefaultBotStopable
    ? [chatContext.defaultBot]
    : getAllStoppableBots(botsThatActuallyStream);

  const isBotTyping = Object.keys(botUsersThatTyping).length > 0;

  const displayFollowUpQuestion =
    enableFollowupQuestion &&
    todoId &&
    sessionId &&
    !displayStop &&
    !isBotTyping;
  const displayBotSuggestions =
    lastMessageId && todoId && sessionId && !displayStop && !isBotTyping;

  return (
    <>
      {Object.keys(botUsersThatTyping)?.map((botId) => {
        // If in botsThatActuallyStream return null
        if (botsThatActuallyStream[botId]) return null;
        return (
          <SingleBotRealtimeUpdate
            key={botId}
            theUser={botUsersThatTyping[botId]}
          />
        );
      })}
      {displayStop ? (
        <CustomBox
          sx={{
            position: 'absolute',
            bottom: '110px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <StopChat
            onStop={() => {
              handleStop();
            }}
            botIds={allStoppableBots.map((bot: any) => bot.id)}
          />
        </CustomBox>
      ) : null}
      {displayBotSuggestions ? (
        <CustomBox>
          <BotSuggestions messageId={lastMessageId} />
        </CustomBox>
      ) : null}
      {displayFollowUpQuestion && !displayBotSuggestions ? (
        <FollowUpQuestions
          onMessageSent={onNewMessage}
          sessionId={sessionId}
          todoId={todoId}
        />
      ) : null}
    </>
  );
};

export default React.memo(RealTimeUpdate);
