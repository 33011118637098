import { useMutation } from '@apollo/client';
import { graphql } from '../gql/gql';

export const RE_GENERATE_TEXT_REQUEST = graphql(`
  mutation improvePrompt($prompt: String!) {
    improvePrompt(prompt: $prompt)
  }
`);

const useImprovePrompt = (prompt: string) => {
  const [updatePreference, { data, loading }] = useMutation(
    RE_GENERATE_TEXT_REQUEST
  );

  const reGeneratePrompt = () => {
    updatePreference({
      variables: {
        prompt: prompt,
      },
    });
  };
  return {
    loading,
    newPrompt: data?.improvePrompt || '',
    reGeneratePrompt,
  };
};

export default useImprovePrompt;
