import { useCallback, useState } from 'react';

const useScroolButton = ({ messages }: { messages: any[] }) => {
  const [isDisplayScrollButton, setIsDisplayScrollButton] = useState(false);
  const scrollToBottomRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (node && !isDisplayScrollButton) {
        node.scrollTop = node.scrollHeight;
      }
    },
    [messages, isDisplayScrollButton]
  );
  const handleScrollBottom = () => {
    setIsDisplayScrollButton(false);
  };
  const onScroll = (e: React.UIEvent<HTMLElement>) => {
    const target = e.currentTarget;
    const { scrollTop, scrollHeight, clientHeight } = target;
    const atBottom = scrollTop + clientHeight >= scrollHeight - 10;
    setIsDisplayScrollButton(!atBottom);
  };

  return {
    onScroll,
    scrollToBottomRef,
    handleScrollBottom,
    isDisplayScrollButton,
  };
};

export default useScroolButton;
