import { FC } from 'react';
import { BotDetailsType } from '../../../types/BotDetails';
import CustomBox from '../CustomBox/CustomBox';
import UserAvatar from '../UserAvatar/UserAvatar';
import CustomTypography from '../CustomTypography/CustomTypography';
import BotCreditAndUpgrade from './BotCreditAndupgrade';
import CustomTemperature from './CustomTemperature/CustomTemperature';
import BotAttributes from './BotAttributes/BotAttributes';

interface IProps {
  bot: BotDetailsType;
  displayCreditInfo?: boolean;
  currentUserEmail: string;
  isBotConfigMode?: boolean;
  hideUpgradeButton?: boolean;
  baseBot?: BotDetailsType;
  isDisplayBotAttributes?: boolean;
}

const BotDetails: FC<IProps> = ({
  bot,
  displayCreditInfo,
  currentUserEmail,
  hideUpgradeButton,
  isDisplayBotAttributes,
  baseBot,
}) => {
  return (
    <CustomBox
      sx={{
        backgroundColor: (theme) => theme.palette.customColor.messageBackground,
        display: 'flex',
        padding: (theme) => theme.spacing(2),
        borderRadius: (theme) => theme.spacing(1),
      }}
    >
      <CustomBox
        sx={{
          marginRight: (theme) => theme.spacing(2),
        }}
      >
        <UserAvatar
          sx={{
            width: '24px',
            height: '24px',
          }}
          userProfil={bot?.user?.profilePicture}
        />
      </CustomBox>
      <CustomBox
        sx={{
          flexGrow: 1,
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
        }}
      >
        <CustomBox>
          <CustomBox
            display={'flex'}
            sx={{
              marginBottom: (theme) => theme.spacing(1),
            }}
            width={'100%'}
          >
            <CustomTypography variant="h4" fontWeight="bold">
              {bot.name}
            </CustomTypography>
            {bot.enableUserCustomization ? (
              <CustomTemperature botId={bot.id} />
            ) : null}
          </CustomBox>
          <CustomTypography variant="body1">{bot.description}</CustomTypography>
          {baseBot && (
            <CustomTypography
              variant="caption"
              sx={{
                fontSize: '12px',
              }}
            >
              {baseBot?.name}
            </CustomTypography>
          )}
        </CustomBox>
        {isDisplayBotAttributes && (
          <CustomBox mt={1}>
            <BotAttributes botId={bot.id} />
          </CustomBox>
        )}

        {!hideUpgradeButton && (
          <CustomBox mt={1}>
            <BotCreditAndUpgrade
              bot={bot}
              displayCreditInfo={displayCreditInfo}
              currentUserEmail={currentUserEmail}
            />
          </CustomBox>
        )}
      </CustomBox>
    </CustomBox>
  );
};

export default BotDetails;
