import { FC, ReactNode, useState } from 'react';
import CustomBox from '../CustomBox/CustomBox';
import { useDropzone } from 'react-dropzone';
import useDisplayMessage from '../GlobalMessage/useDisplayMessage';
import { UploadFileOutlined } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import CustomTypography from '../CustomTypography/CustomTypography';

interface IProps {
  onDropFile: (acceptedFiles: File[]) => void;
  multiple?: boolean;
  maxFileSize?: number;
  disabled?: boolean;
  children: ReactNode;
  option?: {
    cannotClick?: boolean;
  };
  sx?: any;
}

const CustomDropZone: FC<IProps> = ({
  onDropFile,
  multiple,
  maxFileSize,
  disabled,
  children,
  option,
  sx,
}) => {
  const [hoverDraggable, setHoverDraggable] = useState(false);
  const { displayMessage } = useDisplayMessage();

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDropFile,
    multiple,
    disabled: disabled || option?.cannotClick ? !hoverDraggable : false,
    maxSize: maxFileSize,
    onDropRejected: (fileRejections: any) => {
      displayMessage({
        message: 'file.too.large',
        type: 'error',
      });
    },
  });

  const isHOverDrag = () =>
    !hoverDraggable && !disabled ? setHoverDraggable(true) : null;
  const notHOverDrag = () =>
    hoverDraggable && !disabled ? setHoverDraggable(false) : null;

  return (
    <CustomBox
      onDragOver={isHOverDrag}
      onDrop={notHOverDrag}
      onDragLeave={notHOverDrag}
    >
      <CustomBox
        {...getRootProps()}
        sx={{
          ...sx,
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <input {...getInputProps()} />
        {hoverDraggable ? (
          <CustomBox
            disable={true}
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              background: 'rgba(0,0,0,0.5)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              zIndex: 10,
            }}
          >
            <CustomBox>
              <UploadFileOutlined />
            </CustomBox>
            <CustomTypography variant="caption">
              <FormattedMessage id={'fileForm.dropfileIndication'} />
            </CustomTypography>
          </CustomBox>
        ) : null}
        <CustomBox>{children}</CustomBox>
      </CustomBox>
    </CustomBox>
  );
};

export default CustomDropZone;
