export const getFileNameFromUrl = (url: string): string => {
  // Create a new URL object
  const urlObj = new URL(url);

  // Get the pathname from the URL
  const pathSegments = urlObj.pathname.split('/');

  // The file name is the last segment in the pathname
  const encodedFileName = pathSegments[pathSegments.length - 1];

  // Decode the file name
  const fileName = decodeURIComponent(encodedFileName);

  return fileName;
};
