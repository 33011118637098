import { Fab, FabProps } from '@mui/material';
import CustomTooltip from '../CustomToolTip/CustomToolTip';
import Loading from '../Loading/Loading';

interface CustomFabProps extends FabProps {
  intlId: string;
  onClick?: (e: any) => void;
  isLoading?: boolean;
}

const CustomFab = ({ intlId, onClick, isLoading, ...rest }: CustomFabProps) => {
  return (
    <CustomTooltip arrow intlId={intlId} title={''}>
      <Fab onClick={onClick} {...rest}>
        {isLoading ? (
          <Loading type="circular" size={20} />
        ) : (
          <>{rest.children}</>
        )}
      </Fab>
    </CustomTooltip>
  );
};

export default CustomFab;
