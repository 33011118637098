import * as React from 'react';
import CustomBox from '../CustomBox/CustomBox';
import CustomPopover from '../CustomPopover/CustomPopover';
import { BoxStyled } from '../../Project/AddProject/AddProject';
import TextInput from '../TextInput/TextInput';
import { BoxProps } from '@mui/material';
import CustomTypography from '../CustomTypography/CustomTypography';
import { FormattedMessage } from 'react-intl';
import Loading from '../Loading/Loading';

interface OptionsProps extends BoxProps {
  renderAutoCompleteContent: (option: any) => React.ReactNode;
  autoCompletePlaceholder: string;
  renderAutoCompleteButton: React.ReactNode;
  childrenAction?: React.ReactNode;
  options: any[];
  isLoad?: boolean;
  inputName: string;
  inputValue: string;
  noOptiosMessage?: string;
  onKeyPress?(e: any): void;
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
  handleClickOption: (option: any) => void;
}

export default function STAutoComplete({
  renderAutoCompleteContent,
  autoCompletePlaceholder,
  renderAutoCompleteButton,
  noOptiosMessage,
  isLoad,
  inputName,
  childrenAction,
  inputValue,
  options,
  onChange,
  onKeyPress,
  handleClickOption,
  ...rest
}: OptionsProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <>
      <CustomBox sx={{ width: '100%' }}>
        <CustomBox onClick={handleClick}>{renderAutoCompleteButton}</CustomBox>
      </CustomBox>
      <CustomPopover
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        childrenContent={
          <BoxStyled sx={{ mt: -3 }}>
            <TextInput
              value={inputValue}
              onKeyPress={(e: any) => onKeyPress && onKeyPress(e)}
              multiline={true}
              rows={1}
              name={inputName}
              placeholder={autoCompletePlaceholder}
              onChange={onChange}
            />
            <CustomBox {...rest}>
              {isLoad ? (
                <CustomBox display={'flex'} width={'100%'}>
                  <CustomBox mx="auto">
                    <Loading color="inherit" type="circular" size={30} />
                  </CustomBox>
                </CustomBox>
              ) : options.length === 0 ? (
                <CustomBox display={'flex'} width={'100%'}>
                  <CustomTypography mx="auto">
                    <FormattedMessage id={noOptiosMessage} />
                  </CustomTypography>
                </CustomBox>
              ) : (
                options?.map((option: any) => (
                  <CustomBox onClick={() => handleClickOption(option)}>
                    {renderAutoCompleteContent(option)}
                  </CustomBox>
                ))
              )}
            </CustomBox>
          </BoxStyled>
        }
        childrenAction={childrenAction}
      />
    </>
  );
}
