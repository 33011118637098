import { FC } from 'react';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';

interface IProps {
  options: {
    value: string | number;
    label: string;
  }[];
  value: string | number;
  onChange(e: any): void;

  name?: string;
}

const STSelect: FC<IProps> = ({ options, value, onChange, name }) => {
  return (
    <FormControl fullWidth>
      <NativeSelect
        value={value}
        inputProps={{
          name: name,
        }}
        onChange={onChange}
      >
        <option></option>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </NativeSelect>
    </FormControl>
  );
};

export default STSelect;
