import { useState } from 'react';
import { File } from '../../../gql/graphql';

const useFiles = () => {
  const [files, setFiles] = useState<File[] | []>([]);

  return {
    files,
    setFiles,
  };
};

export default useFiles;
