import { useLazyQuery } from '@apollo/client';
import { graphql } from '../gql';
import { useCallback } from 'react';
import { debounce } from 'lodash';
import React from 'react';
import useCreateAndGoChatSession from '../components/Chat/hooks/useCreateAndGoToChatSession';

export const SEARCH_BOTS_QUERY = graphql(
  `
    query GetSearchBots($tagSlug: String, $searchQuery: String) {
      getSearchBots(tagSlug: $tagSlug, searchQuery: $searchQuery) {
        id
        name
        slug
        description
        user {
          id
          firstName
          lastName
          profilePicture
        }
      }
    }
  `
);

const useGetSearchBots = ({
  todoId,
  onBotClick,
}: {
  todoId?: number;
  onBotClick?: (bot: any) => void;
}) => {
  const { onCreateAndGoToChatSession } = useCreateAndGoChatSession({
    todoId,
  });
  const [getMatchingBots, { data, error, loading }] = useLazyQuery(
    SEARCH_BOTS_QUERY,
    {
      fetchPolicy: 'network-only',
    }
  );

  const debouncedGetMatchingBots = useCallback(
    debounce((searchQuery?: string, tagSlug?: string) => {
      getMatchingBots({ variables: { searchQuery, tagSlug } });
    }, 500),
    []
  );

  const [tagSlug, setTagSlug] = React.useState('recent');
  const [inputValue, setInput] = React.useState('');

  React.useEffect(() => {
    if (!inputValue && tagSlug === 'recent') {
      debouncedGetMatchingBots('', 'recent');
    }
  }, []);

  const onChangeInput = (e: any) => {
    const newSearchBot = e.target.value;
    setInput(newSearchBot);
    debouncedGetMatchingBots(newSearchBot, '');
  };
  const onTagClick = (item: any) => {
    setTagSlug(item.slug);
    debouncedGetMatchingBots('', item.slug);
  };
  const handleBotClick = (bot: any) => {
    onCreateAndGoToChatSession(bot);
    onBotClick && onBotClick(bot);
  };
  return {
    debouncedGetMatchingBots,
    data,
    error,
    loading,
    handleBotClick,
    onTagClick,
    onChangeInput,
    inputValue,
    tagSlug,
  };
};

export default useGetSearchBots;
