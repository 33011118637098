// React FC component

import { useMutation, useQuery } from '@apollo/client';
import { Box } from '@mui/system';
import axios from 'axios';
import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import { graphql } from '../../../../gql';
import { Mixpanel } from '../../../../mixpanel/mixpanel';
import useDisplayMessage from '../../../common/GlobalMessage/useDisplayMessage';
import { onAfterSendMessage } from '../../utils/onAfterSendMessage';
import Dropzone from './MessageInput/DropZone/DropZone';
import Progressbar from './ProgressBar/ProgressBar';
import MessageInput from './MessageInput/MessageInput';
import useExternalMessageInput from '../../hooks/useExternalMessageInput';
import config from '../../../../config/config';
import { useIntl } from 'react-intl';
import useDropFiles from '../../hooks/useDropFiles';
import { ContentType } from '../../../../gql/graphql';
import useChatForm from './useChatForm';
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition';
import { usePaste } from '../../../../Hooks/usePaste';
import { useContextFormChat } from '../../hooks/useContextFormChat';

export const ADD_NEW_MESSAGE = graphql(`
  mutation SendNewMessage($newMessageData: NewMessageInput!) {
    sendNewMessage(newMessageData: $newMessageData) {
      id
      createdAt
      content
      sentByUserId
      todoChatId
      updatedAt
      session {
        id
        title
        allDataSources {
          id
          name
          url
          fileType
          type
        }
      }

      files {
        id
        name
        url
        type
        size
        base64Image
      }
      sentBy {
        id
        firstName
        lastName
        profilePicture
      }
      __typename
    }
  }
`);

const BOTS_QUERY = graphql(
  `
    query botsQuery {
      bots {
        id
        name
        slug
        user {
          id
          firstName
          lastName
          profilePicture
        }
      }
    }
  `
);

// Query getinvolved todo users

const GET_INVOLVED_TODO_USERS = graphql(`
  query GetInvolvedTodoUsers($todoId: Int!) {
    todoInvolvedUsers(todoId: $todoId) {
      id
      firstName
      lastName
      profilePicture
      isBot
      bot {
        id
        name
        slug
      }
    }
  }
`);

// File mutations

const SIGNED_URL = graphql(`
  mutation GetFilePutSignedUrl($fileName: String!) {
    getFilePutSignedUrl(fileName: $fileName) {
      key
      url
      uuid
    }
  }
`);
const CREATE_FILE = graphql(`
  mutation CreateFile($newFileData: NewFileInput!) {
    createFile(newFileData: $newFileData) {
      id
      chatMessageId
      url
      updatedAt
      type
      todoId
      size
      removed
      name
      createdById
      createdAt
    }
  }
`);

// React FC component

interface IProps {
  parentMessageId: number | null;
  onMessageSent?: () => void;
  sessionId?: number;
  isBotConfigMode?: boolean;
  isEmptyMessage?: boolean;
}

const FormAddMessage: React.FC<IProps> = ({
  parentMessageId,
  onMessageSent,
  sessionId,
  isBotConfigMode,
  isEmptyMessage,
}) => {
  const { verifyRequiredExtraConfig, extraConfig, files, setFiles } =
    useContextFormChat();

  const { transcript, resetTranscript, listening } = useSpeechRecognition();
  const [messageValue, setMessageValue] = React.useState('');
  const { defaultBot, hasDataSources } = useChatForm();
  const { displayMessage } = useDisplayMessage();
  const { externalMessageInput } = useExternalMessageInput();
  const intl = useIntl();
  // Get todo id from params
  const params = useParams();
  const taskID = params?.taskID ? parseInt(params?.taskID) : 0;
  const sessionIdParam = params?.sessionId ? parseInt(params?.sessionId) : 0;
  const chatSessionId = sessionId || sessionIdParam;

  const [mentionedUser, setMentionedUser] = React.useState<number>();
  // Array of menstion users
  const [mentionedUsers, setMentionedUsers] = React.useState<number[]>([]);
  // const [files, setFiles] = React.useState<File[]>([]);
  const [mentionedBot, setMentionedBot] = React.useState<number>();
  const [isSendMessageLoading, setIsSendMessageLoading] = React.useState(false);
  const [isUploading, setIsUploading] = React.useState(false);
  const [uploadProgress, setUploadProgress] = React.useState<number>(0);
  const [isNoClick, setIsNoClick] = React.useState(true);

  const onChangeForm = (e: any) => {
    // Remove mentionned user if user delete @ or message empty
    resetTranscript();
    if (
      !e.target.value ||
      e.target.value === '' ||
      e.target.value[e.target.value.length - 1] === '@'
    ) {
      setMentionedUser(0);
    }
    setMessageValue(e.target.value);
  };
  const handleValidePrompts = (prompts: string) => {
    Mixpanel.track('Prompts - Add Prompts', {});
    setMessageValue(prompts);
  };
  // Get bots
  const {
    data: botsData,
    error: botsError,
    loading: botsLoading,
  } = useQuery(BOTS_QUERY);

  // Get involved todo users
  const {
    data: involvedTodoUsersData,
    error: involvedTodoUsersError,
    loading: involvedTodoUsersLoading,
  } = useQuery(GET_INVOLVED_TODO_USERS, {
    variables: {
      todoId: taskID,
    },
  });

  // Use effect to set message input value from external message input
  React.useEffect(() => {
    if (externalMessageInput) {
      setMessageValue(externalMessageInput.message);

      if (externalMessageInput.mentionedBotId) {
        onAddMentionUser(externalMessageInput.mentionedBotId, '');
        setMentionedUsers([]);
      }
      // Otherwise set mentioned user to 0
      else {
        setMentionedUser(0);
        setMentionedUsers([]);
      }
    }
  }, [externalMessageInput]);

  //
  const [addNewMessage, { error: errorAddMessage }] = useMutation(
    ADD_NEW_MESSAGE,
    {
      update(cache, { data }) {
        //reset transcription
        resetTranscript();
        setIsSendMessageLoading(false);
        setIsUploading(false);
        setUploadProgress(0);
        setMessageValue('');
        setFiles([]);
        setMentionedBot(0);
        setMentionedUser(0);
        setMentionedUsers([]);
        // Update cache
        onAfterSendMessage({
          cache,
          newMessage: data?.sendNewMessage,
          sessionId: chatSessionId,
          botSlug: defaultBot?.slug,
          trackMixpanel: true,
        });
        // Call onMessageSent
        if (onMessageSent) {
          onMessageSent();
        }
      },
      onError(err) {
        displayMessage({
          message: 'chat.send.message.error',
          type: 'error',
        });
        setIsSendMessageLoading(false);
      },
      // TODO: Optimistic response
    }
  );
  const onSendForm = (e: any) => {
    const hasRequiredExtraConfig = defaultBot?.formFields
      ? verifyRequiredExtraConfig(defaultBot?.formFields)
      : [];

    if (!hasRequiredExtraConfig) {
      return;
    }

    if (listening) {
      //Stop recognition
      SpeechRecognition.stopListening();
    }
    // Upload files
    if (files.length && !isSendMessageLoading) {
      setIsUploading(true);

      setIsSendMessageLoading(true);
      // Use promise all to upload all files at once and then send message
      Promise.all(
        files.map((file: any) => {
          let fileS3key = '';
          return getSignedUrl({
            variables: {
              fileName: file.name,
            },
          })
            .then(async (res: any) => {
              // Upload file
              fileS3key = res.data.getFilePutSignedUrl.key;
              return await axios.put(res.data.getFilePutSignedUrl.url, file, {
                onUploadProgress: (progressEvent: any) => {
                  const progress = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  setUploadProgress(progress);
                },
              });
            })
            .then((res: any) => {
              Mixpanel.track('Upload File In chat', {
                fileType: file.type,
              });
              // Create file
              return addNewFile({
                variables: {
                  newFileData: {
                    name: file.name,
                    type: file.type,
                    size: file.size,
                    s3key: fileS3key,
                  },
                },
              });
            });
        })
      ).then((result) => {
        // Send message
        addNewMessage({
          variables: {
            newMessageData: {
              message: messageValue + transcript,
              mentionedBotId: mentionedBot,
              fileIds: result.map((file: any) => file.data.createFile.id),
              mentionedUserId: mentionedUser,
              parentMessageId,
              sessionId: chatSessionId,
              mentionedUserIds: mentionedUsers,
              extraConfig: extraConfig,
            },
          },
        });
      });
    } else {
      e.preventDefault();
      const isPromptRequired = defaultBot?.isPromptRequired;
      if (
        ((messageValue || transcript) && !isSendMessageLoading) ||
        (!isPromptRequired && !messageValue)
      ) {
        setIsSendMessageLoading(true);
        addNewMessage({
          variables: {
            newMessageData: {
              message: messageValue + transcript,
              mentionedBotId: mentionedBot,
              mentionedUserId: mentionedUser,
              parentMessageId,
              sessionId: chatSessionId,
              mentionedUserIds: mentionedUsers,
              extraConfig: extraConfig,
            },
          },
        });
      }
    }
  };

  const onMouseEnterDropzone = () => {
    setIsNoClick(false);
  };
  const onMouseLeaveDropzone = () => {
    setIsNoClick(true);
  };

  const callBackPaste = (callback: any) => {
    setFiles((prevFiles: any) =>
      prevFiles.concat(
        Object.assign(callback, {
          preview: URL.createObjectURL(callback),
        })
      )
    );
  };

  const { onPasteReturnBlob } = usePaste({ callBackBlob: callBackPaste });
  // Upload files mutations

  const [addNewFile]: any = useMutation(CREATE_FILE, {
    update(cache, { data }) {
      if (data?.createFile) {
        Mixpanel.track('Upload File In chat', {
          fileType: data?.createFile.type,
          size: data?.createFile.size,
        });
      }
    },
  });
  // call mutation SignedUrl
  const [getSignedUrl] = useMutation(SIGNED_URL, {});
  const handleKeyDown = (
    event:
      | React.KeyboardEvent<HTMLInputElement>
      | React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    if (event.key === 'Enter' && event.shiftKey) {
      // Do nothing when shift is pressed and enter is pressed
      // This is to allow for new lines
    } else if (event.key === 'Enter') {
      event.preventDefault();
      onSendForm(event);
    }
  };

  const onAddMentionUser = (id: number | string, display: string) => {
    // Mixpanel track
    Mixpanel.track('Mention User/Bot In Todo Chat', {
      userId: id,
    });
    setMentionedUser(id as number);
    setMentionedUsers((prevMentionedUsers: number[]) => {
      if (prevMentionedUsers.includes(id as number)) {
        return prevMentionedUsers;
      }
      return [...prevMentionedUsers, id as number];
    });
  };

  const onDelete = (index: number) => {
    files.splice(index, 1);
    setFiles([...files]);
  };
  const handelFileRecordingChange = (file: any) => {
    setFiles((prevFiles: any) =>
      prevFiles.concat(
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  };

  // DON'T DISPLAY FORM ON FIRST SESSION IF DISPLAY_FILE_FORM_ON_FIRST_SESSION IS TRUE
  if (
    defaultBot?.isDisplayFileFormOnFirstSession &&
    isEmptyMessage &&
    !hasDataSources
  ) {
    return null;
  }

  return (
    <Box width="100%">
      {files.length > 0 && !isSendMessageLoading ? (
        <Dropzone files={files} onDelete={onDelete} />
      ) : (
        isUploading && <Progressbar uploadProgress={uploadProgress} />
      )}
      <Box m="auto">
        <MessageInput
          messageValue={messageValue + transcript}
          onChangeForm={onChangeForm}
          onSendForm={onSendForm}
          onKeyDown={handleKeyDown}
          placeholder={
            defaultBot?.messageInputPlaceHolder
              ? defaultBot?.messageInputPlaceHolder
              : intl.formatMessage({
                  id: 'chat.messageInput.placeholder',
                  defaultMessage: 'Type a message',
                })
          }
          bots={botsData?.bots || []}
          involvedUsers={involvedTodoUsersData?.todoInvolvedUsers || []}
          onAddMentionUser={onAddMentionUser}
          onPaste={onPasteReturnBlob}
          acceptFileInput={defaultBot?.acceptFileInput as boolean}
          handelFileRecordingChange={handelFileRecordingChange}
          files={files}
          handleValidePrompts={handleValidePrompts}
          isSendMessageLoading={isSendMessageLoading}
          onMouseEnterDropzone={onMouseEnterDropzone}
          onMouseLeaveDropzone={onMouseLeaveDropzone}
          isBotConfigMode={isBotConfigMode}
        />
      </Box>
    </Box>
  );
};

export default FormAddMessage;
