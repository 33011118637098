import { useQuery } from '@apollo/client';
import { graphql } from '../../../gql';
import React from 'react';
import { useParams } from 'react-router-dom';

export const GET_CHAT = graphql(`
  query sessionMessages($sessionId: Float!) {
    sessionMessages(sessionId: $sessionId) {
      ...ChatMessageFragment
    }
  }
`);

const useGetChatTodo = ({
  todoId,
  sessionId,
}: {
  todoId: number;
  sessionId?: number;
}) => {
  const params = useParams();
  let theSessionId = sessionId || +(params?.sessionId ? params.sessionId : 0);

  const { data, error, loading, refetch } = useQuery(GET_CHAT, {
    fetchPolicy: 'cache-first',
    variables: {
      sessionId: theSessionId,
    },
  });
  React.useEffect(() => {
    refetch();
  }, [params?.sessionId]);

  return {
    refetch,
    data,
    error,
    loading,
    currentSessionId: theSessionId,
  };
};

export default useGetChatTodo;
