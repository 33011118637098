import React, { FC } from 'react';
import BodyChat from '../BodyChat';
import useGetChatTodo from '../../hooks/useGetChatTodo';
import useNotificationReader from '../../hooks/useNotificationReader';
import { Mixpanel } from '../../../../mixpanel/mixpanel';
import ErrorMessageIndication from '../../../common/ErrorMessageIndication/ErrorMessageIndication';
import BodyLoading from '../../BodyLoading/BodyLoading';

import CustomBox from '../../../common/CustomBox/CustomBox';
import ChatContext from './ChatContext';
import useGetChatSessionState from '../../hooks/useGetChatSessionState';
import { Bot } from '../../../../gql/graphql';

interface IProps {
  todoId: number;
  sessionId?: number;
  isBotConfigMode?: boolean;
}

const ChatBodyContainer: FC<IProps> = ({
  todoId,
  sessionId,
  isBotConfigMode,
}) => {
  const { data, error, loading, currentSessionId } = useGetChatTodo({
    todoId,
    sessionId,
  });
  useNotificationReader();
  const { defaultBot, dataSources } = useGetChatSessionState();

  React.useEffect(() => {
    Mixpanel.track('Chat page view', {
      todoId,
    });
  }, [todoId]);

  if (loading) {
    return (
      <CustomBox
        sx={{
          width: '100%',
        }}
      >
        <BodyLoading />
      </CustomBox>
    );
  } else if (error) {
    return (
      <CustomBox width="100%" m="auto">
        <ErrorMessageIndication message="message.error" />
      </CustomBox>
    );
  }
  const chatId = 0;
  return (
    <ChatContext.Provider
      value={{
        sessionId: currentSessionId,
        isBotConfigMode,
        defaultBot: defaultBot as Bot,
        dataSources,
        todoChatId: chatId,
        isStoppable: defaultBot?.isStoppable || false,
        enableFollowupQuestion: defaultBot?.enableFollowupQuestion ?? false,
        todoId,
      }}
    >
      <BodyChat
        todoId={todoId}
        messages={data?.sessionMessages}
        sessionId={currentSessionId}
        isBotConfigMode={isBotConfigMode}
      />
    </ChatContext.Provider>
  );
};

export default ChatBodyContainer;
