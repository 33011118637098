import * as React from 'react';
import { styled, TextField, useTheme } from '@mui/material';

export const StyledTextField = styled(TextField)(({ theme }) => ({
  '& *': {
    color: theme.palette.text.primary,
  },
  '& .MuiFormLabel-root,& .MuiInputLabel-root.Mui-focused': {
    color: theme.palette.text.primary,
  },
  'input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,input:-webkit-autofill:active':
    {
      transition: 'background-color 5000s ease-in-out 0s',
      '-webkit-box-shadow': 'inherit',
      '*': {
        color: theme.palette.text.primary,
      },
    },
}));
export interface propsEmailInput {
  email: string;
  placeholder: string | undefined;
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
}
const EmailInput = ({ onChange, placeholder, email }: propsEmailInput) => {
  const theme = useTheme();

  return (
    <StyledTextField
      margin="normal"
      required
      value={email || ''}
      fullWidth
      onChange={(event: any) => onChange(event)}
      id="email"
      placeholder={placeholder}
      inputProps={{
        'data-testid': 'email-input',
        style: {
          backgroundColor: theme.palette.grey[200],
          color: theme.palette.text.primary,
        },
      }}
      name="email"
      autoComplete="email"
    />
  );
};

export default EmailInput;
